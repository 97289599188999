<template>

  <v-stepper v-model="processIndex" vertical>

    <v-snackbar top color="error" v-model="error" :timeout="8000">
      Une erreur est survenue durant la procédure. <pre>{{errorMessage}}</pre>
    </v-snackbar>

    <v-stepper-step :complete="processIndex > processes.targetConfirmation.index" :step="processes.targetConfirmation.index">Détail de la connexion</v-stepper-step>
    <v-stepper-content :step="processes.targetConfirmation.index">
      <v-card color="light-blue lighten-5" class="mb-12">
        <v-card-title><y-branch-label :value="branchUuid"/></v-card-title>
        <v-card-subtitle><y-company-label :value="companyUuid"/></v-card-subtitle>
        <v-card-text>
          <v-radio-group v-if="!sisterBranch" v-model="processes.targetConfirmation.sales">
            <v-radio label="Publication des cours seulement" :value="false"/>
            <v-radio label="Publication et prise de commande en ligne" :value="true"/>
          </v-radio-group>
          <span v-else>Activation de la récupération des commandes en ligne seulement, pas d'envoi de cours.</span>

          <v-checkbox v-if="!sisterBranch" v-model="processes.targetConfirmation.initDatabase" label="Initialiser la base de données" persistent-hint hint="Rajoutera les tables manquantes au bon fonctionnement de Web-Yodaforex Connect. N'impactera pas les tables existantes ni leurs données. Nécessite une base MySQL récente."/>

        </v-card-text>
      </v-card>
      <v-btn :disabled="complete" color="primary" @click="startProcessChain">Suivant</v-btn>
    </v-stepper-content>

    <v-stepper-step v-if="processes.targetConfirmation.initDatabase" :complete="processIndex > processes.initializingDatabase.index" :step="processes.initializingDatabase.index" :rules="[()=>!processes.initializingDatabase.error]">Initialisation de la base de données</v-stepper-step>
    <v-stepper-content v-if="processes.targetConfirmation.initDatabase" :step="processes.initializingDatabase.index">

    </v-stepper-content>

    <v-stepper-step :complete="processIndex > processes.fetchingYodaforexLicense.index" :step="processes.fetchingYodaforexLicense.index" :rules="[()=>!processes.fetchingYodaforexLicense.error]">Récupération de la license Yodaforex</v-stepper-step>

    <v-stepper-step :complete="processIndex > processes.creatingYodaforexKey.index" :step="processes.creatingYodaforexKey.index" :rules="[()=>!processes.creatingYodaforexKey.error]">
      Génération de la clé Yodaforex
      <small v-if="processes.creatingYodaforexKey.generatedKey" class="ml-2">{{processes.creatingYodaforexKey.generatedKey}}</small>
      <small v-if="processes.creatingYodaforexKey.alreadyGenerated" class="ml-2">Clé déjà présente sur la licence, pas nécessaire de re-générer une nouvelle clé.</small>
    </v-stepper-step>

    <v-stepper-step :complete="processIndex > processes.updateLicense.index" :step="processes.updateLicense.index" :rules="[()=>!processes.updateLicense.error]">Ajout de la clé à la license et modification des options web</v-stepper-step>

    <v-stepper-step v-if="!sisterBranch" :complete="processIndex > processes.creatingWebmasterKey.index" :step="processes.creatingWebmasterKey.index" :rules="[()=>!processes.creatingWebmasterKey.error]">Génération de la clé webmaster</v-stepper-step>
    <v-stepper-content v-if="!sisterBranch" :step="processes.creatingWebmasterKey.index">
      <v-card>
        <v-card-subtitle>Création d'une clé {{webmasterKeyRole}}<v-progress-circular class="ml-4" size="16" indeterminate v-if="processes.creatingWebmasterKey.loading"/></v-card-subtitle>
        <v-card-text v-if="processes.creatingWebmasterKey.error">
          <v-alert type="error">Une erreur est survenue</v-alert>
        </v-card-text>
        <v-card-text v-else-if="processes.creatingWebmasterKey.generatedKey">
          <v-text-field label="Clé à transmettre au webmaster" readonly v-model="processes.creatingWebmasterKey.generatedKey"/>
        </v-card-text>
      </v-card>
    </v-stepper-content>

    <v-alert v-if="complete" type="success">Procédure terminée !</v-alert>
  </v-stepper>
</template>

<script>

export default {
  name: "MagicWebco",
  props: {
    branchUuid: String,
    companyUuid: String,
    sisterBranch: { // Indique si l'agence traitée est l'agence principale ou une agence "soeur" qui ne pourra que récupérer les commandes
      type: Boolean,
      default: false
    },
    initDatabaseAsDefault: { // Indique si la case à cocher "initialiser la base de données" est cochée par défaut
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    processIndex: 1,
    complete: false,
    error: false,
    errorMessage: null,

    processes: {
      targetConfirmation: {
        index: 1, // Les index des tâches sont réactives car la liste des tâches change suivant les options du composant (sisterBranch et processes.targetConfirmation.initDatabase)
        selectedBranch: null,
        selectedBranchUuid: "",
        selectedCompanyUuid: "",
        sales: false,
        initDatabase: true,
      },
      initializingDatabase: {
        index: 2,
        error: false,
      },
      fetchingYodaforexLicense: {
        index: 3,
        error: false,
        branch: null,
      },
      creatingYodaforexKey: {
        index: 4,
        error: false,
        generatedKey: null,
        alreadyGenerated: false
      },
      updateLicense: {
        index: 5,
        error: false,
      },
      creatingWebmasterKey: {
        index: 6,
        loading: false,
        error: false,
        generatedKey: null
      }
    },
  }),
  watch: {
    branchUuid() {
      this.prepareForm();
    },
    companyUuid() {
      this.prepareForm();
    },
    sisterBranch() {
      this.prepareForm();
    },
    initDatabaseAsDefault() {
      this.prepareForm();
    },
    "processes.targetConfirmation.selectedBranch"(v) {
      if (v.uuid) {
        this.processes.targetConfirmation.selectedBranchUuid = v.uuid;
        this.processes.targetConfirmation.selectedCompanyUuid = v.companyUuid;
      }
    },
    "processes.targetConfirmation.initDatabase"(v) {
      if (v) { // Si l'option d'initialisation de la base de données est activée, on incrémente l'index des tâches qui suivent
        this.processes.fetchingYodaforexLicense.index++;
        this.processes.creatingYodaforexKey.index++;
        this.processes.updateLicense.index++;
        this.processes.creatingWebmasterKey.index++;
      } else { // Si on vient de la désactiver, on fait l'inverse
        this.processes.fetchingYodaforexLicense.index--;
        this.processes.creatingYodaforexKey.index--;
        this.processes.updateLicense.index--;
        this.processes.creatingWebmasterKey.index--;
      }
    },
  },
  computed: {
    webmasterKeyRole() {
      return this.processes.targetConfirmation.sales ? 'website-sales' : 'website-booking';
    }
  },
  mounted() {
    this.prepareForm();
  },
  methods: {
    async startProcessChain() {
      try {
        if (this.processes.targetConfirmation.initDatabase) {
          this.processIndex++;
          await this.initializeDatabase();
        }
        this.processIndex++;
        await this.fetchYodaforexLicense();
        this.processIndex++;
        await this.createYodaforexKey();
        this.processIndex++;
        await this.updateLicense();
        if (!this.sisterBranch) { // Pas besoin de créer de jeton webmaster pour les agences soeurs
          this.processIndex++;
          await this.createWebmasterKey();
        }
        this.complete = true;
        this.$emit('success');
      } catch (e) {
        this.error = true;
        this.errorMessage = e;
        for (const processesKey in this.processes) {
          if (this.processes[processesKey].index === this.processIndex) {
            this.processes[processesKey].error = true;
          }
        }
      }
    },
    fetchYodaforexLicense() {
      return new Promise((resolve, reject) => {
        this.$http.get(this.$config.services.licenseApiBaseUrl+"/v1/companies/"+this.processes.targetConfirmation.selectedCompanyUuid+"/branches/"+this.processes.targetConfirmation.selectedBranchUuid)
            .then((r)=>{
              this.processes.fetchingYodaforexLicense.branch = r.data;
              resolve();
            }).catch(reject);
      })
    },
    createYodaforexKey() {
      return new Promise((resolve, reject) => {
        if (this.processes.fetchingYodaforexLicense.branch.license.conf['options.web.api.token']) {
          this.processes.creatingYodaforexKey.alreadyGenerated = true;
          resolve();
        } else {
          this.$http.post(this.$config.services.authApiBaseUrl+"/v1/tokens", {
            companyUuid: this.processes.targetConfirmation.selectedCompanyUuid,
            branchUuid: this.processes.targetConfirmation.selectedBranchUuid,
            serviceName: "web-yfx-connect",
            role: "yodaforex",
            label: "MagicWebco Yfx pour " + this.processes.fetchingYodaforexLicense.branch.name
          }).then((r)=>{
            this.processes.creatingYodaforexKey.generatedKey = r.data.tokenString;
            resolve();
          }).catch(reject);
        }
      })
    },
    updateLicense() {
      let payload = {
        "options.web.api.url": this.$config.services.webcoApiBaseUrl,
        "options.web.publication.actif": !this.sisterBranch, // On ne veut PAS de publication chez les agences soeurs
        "options.web.vente.actif": !!this.processes.targetConfirmation.sales || this.sisterBranch // Les sister branches ont forcément les commandes activées, c'est leur rôle
      };
      if (!this.processes.creatingYodaforexKey.alreadyGenerated) { // Si on a généré une nouvelle clé, on l'indique aussi
        payload["options.web.api.token"] = this.processes.creatingYodaforexKey.generatedKey;
      }
      return this.$http.post(this.$config.services.licenseApiBaseUrl+"/v1/branches/"+this.processes.targetConfirmation.selectedBranchUuid+"/license/conf-light", payload)
    },
    createWebmasterKey() {
      return new Promise((resolve, reject) => {
        this.$http.post(this.$config.services.authApiBaseUrl+"/v1/tokens", {
          companyUuid: this.processes.targetConfirmation.selectedCompanyUuid,
          branchUuid: this.processes.targetConfirmation.selectedBranchUuid,
          serviceName: "web-yfx-connect",
          role: this.webmasterKeyRole,
          label: "MagicWebco Web pour " + this.processes.fetchingYodaforexLicense.branch.name
        }).then((r) => {
          this.processes.creatingWebmasterKey.generatedKey = r.data.tokenString;
          resolve();
        }).catch(reject);
      })
    },
    initializeDatabase() {
      return this.$http.post(this.$config.apiUrl + "/v1/maintenance/initialization", {
        companyUuid: this.companyUuid,
        branchUuid: this.branchUuid,
        type: !!this.processes.targetConfirmation.sales ? 'SALES' : 'BOOKING'
      });
    },
    prepareForm() {
      this.processes.targetConfirmation.selectedBranch = this.branchUuid;
      this.processes.targetConfirmation.selectedBranchUuid = this.branchUuid;
      this.processes.targetConfirmation.selectedCompanyUuid = this.companyUuid;
      this.processes.targetConfirmation.initDatabase = this.initDatabaseAsDefault && !this.sisterBranch;
    }
  }

}
</script>

<style scoped>

</style>
