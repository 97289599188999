<template>
  <v-card :loading="loading" :disabled="loading" :color="isDemo ? 'teal' : 'white'" :dark="isDemo">


    <!-- Dialog Magic Webco -->
    <v-dialog v-model="showMagicWebcoDialog">
      <v-card>
        <v-card-title>
          Magic Webco
          <span v-if="isMagicWebcoSisterBranch" class="purple--text ml-2">pour l'agence soeur <y-branch-label class="ml-1" :value="magicWebcoBranchUuid"/></span>
        </v-card-title>
        <v-card-subtitle>Initialisation automatique de l'écosystème Web-Yodaforex Connect</v-card-subtitle>
        <v-card-text>
          <magic-webco v-if="showMagicWebcoDialog" :branch-uuid="magicWebcoBranchUuid" :sister-branch="isMagicWebcoSisterBranch" :company-uuid="formData.companyUuid" :init-database-as-default="!isUpdating" @success="isMagicWebcoSuccess=true"/>
          <p v-else>Chargement...</p>
        </v-card-text>

        <v-card-actions v-if="isUpdating">
          <v-btn @click="showMagicWebcoDialog=false" block>Fermer</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn v-if="isMagicWebcoSuccess" color="success" @click="magicWebcoCloseWithInit" block>Terminer</v-btn>
          <v-btn v-else @click="magicWebcoCloseWithInit" block>Ne pas exécuter l'assistant et terminer</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- Dialog Confirmation Suppression -->
    <v-dialog max-width="400" v-model="deleteConfirmationDialog">
      <v-card color="error" :loading="loadingDelete">
        <v-card-title>Êtes-vous sûr ?</v-card-title>
        <v-card-text v-if="entryToUpdate">Supprimer définitivement l'entrée du registre pour la société <y-company-label :value="entryToUpdate.companyUuid"/> - agence <y-branch-label :value="entryToUpdate.branchUuid"/> - description {{entryToUpdate.description}} ? La base de données ne sera pas impactée.</v-card-text>
        <v-card-actions>
          <v-btn color="white" text @click="deleteConfirmationDialog=false">Annuler</v-btn>
          <v-spacer/>
          <v-btn color="black" text @click="deleteConfirmationClicked" :loading="loadingDelete">J'en suis sûr</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Alerte erreur -->
    <v-snackbar v-model="error" :timeout="5000" color="error" top>Une erreur est survenue<span v-if="errorMessage">&nbsp; : {{errorMessage}}</span></v-snackbar>

    <!-- Vue principale -->

    <v-card-title v-if="!isUpdating">Créer une nouvelle entrée</v-card-title>
    <v-card-title v-else>Modifier une entrée existante</v-card-title>

    <v-card-text>
      <registry-entry-form ref="form" v-model="formData" :update="isUpdating" @magic-webco-on-sister-branch="showMagicWebcoDialogForSisterBranch"/>
    </v-card-text>

    <v-card-actions>
      <v-btn color="secondary" text @click="cancel">Annuler</v-btn>

      <!-- On met à jour une entrée -->
      <template v-if="isUpdating">
        <v-btn color="error" text @click="deleteConfirmationDialog=true">Supprimer</v-btn>
        <v-spacer/>
        <v-btn v-if="showMagicWebcoButton" text @click="showMagicWebcoDialogForMainBranch" color="purple"><v-icon>mdi-magic-staff</v-icon>Init. auto.</v-btn>
        <v-spacer/>
        <v-btn :color="isDemo ? 'warning' : 'primary'" text @click="save" v-text="isDemo ? 'Sauver en tant que démo' : 'Sauvegarder'"/>
      </template>

      <!-- On crée une entrée et magic webco est dispo -->
      <template v-else-if="showMagicWebcoButton">
        <v-spacer/>
        <v-btn text @click="saveAndInit" color="purple"><v-icon left>mdi-magic-staff</v-icon>Créer et initialiser<v-icon right>mdi-chevron-right</v-icon></v-btn>
      </template>

      <!-- On crée une entrée et magic webco n'est PAS dispo -->
      <template v-else>
        <div class="caption text-center mx-6">Le système d'initialisation automatique de la base de données est indisponible, vous devez posséder au moins les droits "Support" pour l'API Licenses, et les droits "Admin" pour l'API Auth.</div>
        <v-btn :color="isDemo ? 'warning' : 'primary'" text @click="magicWebcoCloseWithoutInit">Sauvegarder</v-btn>
      </template>
    </v-card-actions>

  </v-card>
</template>

<script>
import RegistryEntryForm from "@/components/RegistryEntryForm";
import MagicWebco from "@/components/MagicWebco";

export default {
  name: "RegistryEntryCard",
  components: {MagicWebco, RegistryEntryForm},
  data: () => ({
    deleteConfirmationDialog: false,
    loadingDelete: false,
    error: false,
    errorMessage: null,
    loading: false,
    formData: null,
    showMagicWebcoDialog: false,
    isMagicWebcoInit: false,
    isMagicWebcoSuccess: false,
    createdEntry: null,
    magicWebcoSisterBranchUuid: null // Sera utilisé si on utilise magicwebco pour une agence soeur, restera à NULL si c'est pour l'agence principale
  }),
  props: {
    entryToUpdate: {
      type: Object
    }
  },
  mounted() {
    if (!!this.entryToUpdate) {
      this.formData = this.entryToUpdate;
    }
  },
  computed: {
    magicWebcoBranchUuid() {
      return this.magicWebcoSisterBranchUuid ? this.magicWebcoSisterBranchUuid : this.formData.branchUuid;
    },
    isMagicWebcoSisterBranch() {
      return !!this.magicWebcoSisterBranchUuid;
    },
    isUpdating() {
      return !!this.entryToUpdate;
    },
    isDemo() {
      return this.formData && this.formData.demoMode;
    },
    showMagicWebcoButton() {
      let authorizedServices = 0;
      if (this.$store.state.auth && Array.isArray(this.$store.state.auth.userApps)) {
        let userApps = this.$store.state.auth.userApps;
        for (let i = 0; i < userApps.length; i++) {
          if (userApps[i].identifier === 'yfx-license' || userApps[i].identifier === 'auth') {
            authorizedServices++;
          }
        }
      }
      return authorizedServices >= 2;
    }
  },
  watch: {
    entryToUpdate: {
      deep: true,
      handler: function (newVal) {
        this.showMagicWebcoDialog = false;
        this.isMagicWebcoSuccess = false;
        this.createdEntry = null;
        this.formData = newVal;
      }
    }
  },
  methods: {
    saveAndInit() {
      this._save((data) => {
        this.createdEntry = data;
        this.showMagicWebcoDialog = true;
      })
    },
    save() {
      this._save((data) => {
        this.$emit('save', data);
        this.$refs.form.reset();
      })
    },
    magicWebcoCloseWithoutInit() {
      this.showMagicWebcoDialog = false;
      this.$emit('save', this.createdEntry);
      this.createdEntry = null;
      this.$refs.form.reset();
    },
    magicWebcoCloseWithInit() {
      this.showMagicWebcoDialog = false;
      this.$emit('save', this.createdEntry);
      this.createdEntry = null;
      this.$refs.form.reset();
    },
    _save(onSuccess) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.error = false;
        this.errorMessage = null;
        this.$http.put(this.$config.apiUrl + "/v1/registry/companies/" + this.formData.companyUuid + "/branches/" + this.formData.branchUuid, this.formData)
            .then((r) => {
              onSuccess(r.data);
            })
            .catch((e) => {
              this.error = true;
              if (e.response && e.response.data && e.response.data.message) {
                this.errorMessage = e.response.data.message;
              }
            })
            .finally(() => this.loading = false);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    deleteConfirmationClicked() {
      this.loadingDelete = true;
      this.$http.delete(this.$config.apiUrl + "/v1/registry/companies/" + this.formData.companyUuid + "/branches/" + this.formData.branchUuid)
          .then(()=> this.$emit('save'))
          .catch((e) => this.error = true)
          .finally(() => this.loadingDelete = false);
    },
    showMagicWebcoDialogForMainBranch() {
      this.magicWebcoSisterBranchUuid = null; // C'est pour l'agence principale
      this.showMagicWebcoDialog = true;
    },
    showMagicWebcoDialogForSisterBranch(branchUuid) {
      this.magicWebcoSisterBranchUuid = branchUuid; // C'est pour une agence soeur
      this.showMagicWebcoDialog = true;
    }
  }
}
</script>

<style scoped>

</style>
