<template>
  <div>
    <v-btn :color="buttonColor" :disabled="isDisabled" :loading="isLoading" @click="testConnection" block outlined rounded>{{ message }}</v-btn>
    <v-alert rounded class="mt-6" type="error" v-if="isTested && !isSuccess && errorMessage">{{errorMessage}}</v-alert>
  </div>
</template>

<script>
export default {
  name: "TestConnectionButton",
  props: {
    host: String,
    port: String | Number,
    username: String,
    password: String,
    database: String
  },
  data: () => ({
    isTested: false,
    isSuccess: false,
    isLoading: false,
    errorMessage: null
  }),
  watch: {
    host() {
      this.isTested = false;
    },
    port() {
      this.isTested = false;
    },
    username() {
      this.isTested = false;
    },
    password() {
      this.isTested = false;
    },
    database() {
      this.isTested = false;
    },
  },
  computed: {
    buttonColor() {
      return this.isTested ? (this.isSuccess ? 'success' : 'alert') : 'gray';
    },
    isDisabled() {
      return this.url && this.port && this.username && this.password && this.database;
    },
    message() {
      return this.isTested ? (this.isSuccess ? 'Test réussi' : 'Test échoué') : 'Tester la connexion';
    }
  },
  methods: {
    testConnection() {
      this.isLoading = true;
      this.$http.post(this.$config.apiUrl + "/v1/registry/test-config", {
        host: this.host,
        port: this.port,
        username: this.username,
        password: this.password,
        database: this.database
      })
          .then((r) => {
            this.isSuccess = r.data.success;
            this.errorMessage = r.data.errorMessage;
            this.isTested = true;
          })
          .catch(() => {
            this.isSuccess = false;
            this.errorMessage = "Impossible de tester la connexion à la base de données, une erreur s'est produite.";
            this.isTested = true;
          })
          .finally(() => this.isLoading = false);
    }
  }
}
</script>

<style scoped>

</style>
